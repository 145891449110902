html, body {
  width: 100%;
  height: 100%;
}

.body {
  padding: 1px;
}
.wrapper {
  width: 100%;
  margin:10vh auto 0;
  display: table;
  table-layout: fixed;
  
  @include media($mobile){
    margin: 0 auto;
  }
}


.main-header {
  width: 54%;
  display: table-cell;
  vertical-align: top;
  text-align: right;
  padding: 18px 90px;
  
  
  
  @include media($height){
    img {
      height: 74vh;
    }
  };
  
  @include media($desktop){
    img {
      width: 100%;
      height: auto;
    }
  };
  
  @include media($mobile){
    width: 100%;
    display: block;
    padding: 20px 20px;
  };
  
}

.links {
  display: inline-block;
  vertical-align: bottom;
  margin-top: 30px;
}

.main {
  width: 46%;
  display: table-cell;
  vertical-align: top;
  text-align: left;
  font-size: 14px;
  
  @include media($mobile){
    width: 100%;
    display: block;
    padding: 20px 20px;
  };
}




.icon-link {
  text-align: center;
  color: $black;
  transition: color .3s;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 50px 0 0;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: .1em;
  
  &:hover {
    color: $red;
  }
}

.icon {
  display: block;
  width: 36px;
  height: 35px;
  margin: 0 auto 8px;
}


.address {
  margin-top: 60px;
  
  @include media($height){
    margin-top: 6vh;
  }
}

address {
  display: inline-block;
  vertical-align: bottom;
  font-style: normal;
    margin: 0 50px 0 0;
  
  span {
    display: inline-block;
    margin-right: 8px;
  }
}


.logo {
  position: fixed;
  left: 54%;
  bottom: 0;
  width: 130px;
  height: 79px;
  color: $red;
  background: #fff;
  
  @include media($mobile){ 
    left: auto;
    bottom: 180px;
    right: 0;
    transform: rotate(-90deg);
    transform-origin: right bottom;
  }
}

.tagline {
  width: 256px;
  height: 253px;
  color: $red;
  
  @include media($height){
    height:  30.1428vh; 
    width: 30.1428vh;
  }
}