body {
  font: 400 1em/1.5 $body;
  color: $black;
  background: #fff;
}

#symbols {
  display: none;
}

a {
  color: $black;
  text-decoration: none;
  @include transition(all .3s);
  
  &:hover {
    color: $red;
  }
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h3 {
  margin-top: 0;
}

button {
  background: none;
  border: none;
  @include appearance(none);
  padding: 0;
  margin: 0;
  
  &:focus {
    outline: none;
  }
}

strong {
  font-weight: 700;
}

svg {
  width: 100%;
  height: 100%;
}
